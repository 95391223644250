/*
Creates and updates the values of CSS custom properties from DOM measurements
*/
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

export default class SetCSSProperties {
  constructor() {
    this.gold = "#edb232";
    this.grey = "#f2f2f2";
    this.isScrolling = false;
    this.offsetHeight = document.querySelector("#header").offsetHeight;

    this.setProperties();
    this.setScrolling();
    this.bindResize();
    this.bindScroll();
  }

  setProperties = () => {
    this.setPropHeight("--header-height", "#header");
    this.setPropHeight("--admin-bar-height", "#wpadminbar");
  };

  setPropHeight = (propName, idSelector) => {
    const el = document.querySelector(idSelector);
    if (!el) return null;

    const height = el.getBoundingClientRect().height;
    document.documentElement.style.setProperty(propName, `${height}px`);
  };

  setScrolling = () => {
    if (this.isScrolling !== window.pageYOffset > this.offsetHeight) {
      this.isScrolling = !this.isScrolling;
      document.documentElement.style.setProperty(
        "--notification-background",
        this.isScrolling ? this.grey : this.gold
      );
    }
  };

  bindResize() {
    const debouncedClientSize = debounce(() => this.setProperties(), 200);
    window.addEventListener("resize", debouncedClientSize);
  }

  bindScroll() {
    const onClientScroll = throttle(() => this.setScrolling(), 200);
    window.addEventListener("scroll", onClientScroll);
  }
}
