import Percolator from "../../helpers/Percolator";
import Sb from "../abstract/StatefulBehavior";
import Flickity from "flickity";

export default class Slideshow extends Sb {
  constructor(el, props, refs) {
    super();

    const percolator = new Percolator();

    this.state = {
      active: 0,
    };

    this.el = el;
    this.refs = refs;
    this.props = props;

    this.start = null;

    this.requestId = null;

    // Setup equal heights for flickity
    // Flickity.prototype._createResizeClass = function () {
    //   this.element.classList.add("flickity-resize");
    // };

    // Flickity.createMethods.push("_createResizeClass");

    // const resize = Flickity.prototype.resize;
    // Flickity.prototype.resize = function () {
    //   this.element.classList.remove("flickity-resize");
    //   resize.call(this);
    //   this.element.classList.add("flickity-resize");
    // };

    this.initFlickity();
    // this.update();
    this.bindEvents();
  }

  initFlickity() {
    if (!this.refs.slider) return;

    const sliderEl = this.refs.slider;
    const autoAdvanceEnabled = this.el.getAttribute("data-autoadvance")
      ? 5000
      : false;

    this.slider = new Flickity(sliderEl, {
      cellAlign: "left",
      selectedAttraction: 0.075,
      friction: 0.48,
      pageDots: true,
      prevNextButtons: false,
      adaptiveHeight: true,
      autoPlay: autoAdvanceEnabled,
      pauseAutoPlayOnHover: true,
    });

    setTimeout(() => this.slider.resize(), 100);
  }

  bindEvents() {}

  get slideCount() {
    return this.slider.slides.length;
  }
}
