/*
Resize iframe when resize message is posted from the base origin
*/
import Sb from "../abstract/StatefulBehavior";
import urlParse from "url-parse";

export default class ResizeIframe extends Sb {
  constructor(el, props) {
    super();

    this.state = {
      iframeLoaded: false,
      height: 0,
    };

    this.props = props;
    this.iframe = el;

    if (!this.iframe.src) return null;
    this.baseUrlOrigin = urlParse(this.iframe.src).origin;

    this.bindOnLoad();
    this.bindResizeMessage();
  }

  get scriptSrc() {
    return `${this.props.baseurl}/scripts/integrate.js`;
  }

  update = () => {
    const { iframeLoaded, height } = this.state;

    if (!iframeLoaded || !height) return;

    Object.assign(this.iframe.style, {
      height: `${height}px`,
    });
  };

  handleOnLoad = (event) => {
    if (this.state.iframeLoaded) return;
    this.setState({ iframeLoaded: true });
  };

  handleResizeMessage = ({ data, origin }) => {
    if (
      !this.state.iframeLoaded ||
      !data ||
      data[0] !== "resize" ||
      this.baseUrlOrigin !== origin
    )
      return;
    this.setState({ height: data[1] });
  };

  bindOnLoad() {
    const iframe = this.iframe;

    if (iframe.addEventListener) {
      iframe.addEventListener("load", this.handleOnLoad, false);
    } else {
      iframe.attachEvent("onload", this.handleOnLoad);
    }
  }

  bindResizeMessage() {
    if (window.addEventListener) {
      window.addEventListener("message", this.handleResizeMessage, false);
    } else {
      window.attachEvent("onmessage", this.handleResizeMessage);
    }
  }
}
